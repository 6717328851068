.tabs {
    display: flex;
    justify-content: space-between;
}

.tabs button {
    border-bottom: 2px solid #3070B6;
    color: #3070B6;
    cursor: pointer;
    height: 38px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), #F2F6F9);
}

.tabs button:disabled {
    background: #3070B6;
    color: white;
}

.grid-container > div {
    background-color: #fff;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}
.grid-container {
    grid-gap: 1px;
    margin: 10px;
    padding: 1px;
}
.item3 {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1289px) {
    .iframe {
        width: 500px;
    }
}

@media screen and (max-width: 1229px) {
    .iframe {
        width: 450px;
    }
}

@media screen and (max-width: 1042px) {
    .iframe {
        width: 450px;
        height: 350px;
    }
}

@media screen and (max-width: 967px) {
    .iframe {
        width: 580px;
    }
}

@media screen and (max-width: 685px) {
    .iframe {
        width: 450px;
        height: 250px;
    }
}

@media screen and (max-width: 510px) {
    .iframe {
        width: 350px;
        height: 200px;
    }
}

@media screen and (max-width: 400px) {
    .iframe {
        width: 300px;
        height: 180px;
    }
}

@media screen and (max-width: 1535px) {
    .infoVideo {
        width: 1170px;
        height: 555px;
    }
}

@media screen and (max-width: 1289px) {
    .infoVideo {
        width: 970px;
        height: 555px;
    }
}

@media screen and (max-width: 1129px) {
    .infoVideo {
        width: 800px;
        height: 450px;
    }
}


@media screen and (max-width: 967px) {
    .infoVideo {
        width: 690px;
        height: 400px;
    }
}

@media screen and (max-width: 767px) {
    .infoVideo {
        width: 500px;
        height: 300px;
    }
}

@media screen and (max-width: 640px) {
    .infoVideo {
        width: 440px;
        height: 250px;
    }
}

@media screen and (max-width: 540px) {
    .infoVideo {
        width: 300px;
        height: 200px;
    }
}