
.react-tabs {
    display: flex;
}

.react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 290px;
}

.react-tabs__tab {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 267px;
    list-style: none;
    cursor: pointer;
    border-right: 3px solid #D2D5DA;
}

.react-tabs__tab--selected {
    border-right: 3px solid #3070B6;
    width: 326px;
    position: relative;
    left: -59px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 60px;
    background: rgba(48, 112, 182, 0.2);
    mix-blend-mode: darken;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.react-tabs__tab-panel {
    display: none;

}

.react-tabs__tab-panel--selected {
    display: block;
}

.panel-content {
    text-align: center;
}

@media (max-width: 1050px){
    .react-tabs__tab-list {
        width: 140px;
        font-size: 13px;
    }
    .react-tabs__tab {
        width: 130px;
        padding: 31px 0px;
    }
    .react-tabs__tab--selected {
        width: 190px;
        padding-left: 60px;
    }

}